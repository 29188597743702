.main {
  position: absolute;
  height: 100%;
  width: calc(100% - 78px);
  left: 78px;
  overflow-y: scroll;
  padding: 0 80px;
  transition: all 0.5s ease;
  background-color: #ffecf0;
}

.main.active {
  width: calc(100% - 240px);
  left: 240px;
}
