input {
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(75, 74, 74);
  padding: 4px 20px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #f8f9fa;
}
textarea {
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(75, 74, 74);
  padding: 4px 20px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #f8f9fa;
  resize: none !important;
}

textarea::-webkit-scrollbar {
  width: 0;
}

input:focus,
textarea:focus {
  background-color: #f1f3f4;
  border-bottom: 2px solid #ff045a;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}
