.surveys-list {
  height: 100%;
}

.surveys-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100px;
}

.surveys-list__actions {
  display: flex;
  align-items: center;
}

.surveys-list__actions .search-button {
  display: flex;
  font-size: 26px;
  height: 100%;
  margin-left: 30px;
  color: #11101d;
}

.create-survey-button {
  display: flex;
  font-size: 38px;
  height: 100%;
  margin-left: 30px;
  color: #11101d;
}

.search-input {
  height: 0px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all ease 0.3s;
}

.search-input.active {
  height: 40px;
}

.search-input input {
  position: absolute;
  width: 100%;
  height: 100%;
}

.search-input .search-button {
  position: absolute;
  right: 0;
  font-size: 28px;
  background-color: #11101d;
  color: #b9b9b9;
  height: 100%;
  width: 50px;
}

.surveys-list__table {
  width: 100%;
  height: 100%;
}

.surveys-list__table table {
  margin-top: 20px;
  width: 100%;
}

.surveys-list__table thead {
  border-bottom: 1px solid #333;
}

.th-survey-name {
  min-width: 200px;
}

.survey-status {
  font-size: 18px;
  display: flex;
  justify-content: center;
}

.check-survey,
.check-all-surveys {
  padding: 10px 15px;
}

/* .table-actions {
  display: flex;
  flex-direction: column;
} */

.delete-survey,
.edit-survey {
  font-size: 24px;
  color: #11101d;
  /* display: flex; */
  text-align: center;
  margin: 5px;
}

.td-survey-id {
  text-decoration: none;
  font-weight: bold;
}

.th-created-by,
.th-question-length,
.td-question-length,
.th-survey-status,
.survey-status {
  text-align: center;
}

.active-status {
  font-size: 32px;
  color: #f9bc02;
}
.inactive-status {
  font-size: 32px;
  color: #a7194b;
}

.td-created-by {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

th {
  font-weight: bold;
  font-size: 18px;
}

.survey-created-time {
  font-size: 12px;
}

/* tr {
  line-height: 40px;
} */

.td-survey-name {
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 32px;
}

.survey-update {
  width: 100%;
  max-width: 90vw;
}

.survey-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100px;
  z-index: 1111;
  /* box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.16); */
}

.survey-actions button {
  font-size: 32px;
  margin: 0 10px;
}

.survey-content {
  margin-top: 10px;
  padding: 0 10px;
}

.update-name {
  width: 100%;
  height: 140px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border-top: #ff045a 5px solid;
}

.update-name input,
.survey-name {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  padding: 0;
  background-color: transparent;
}

.update-name input:focus {
  background-color: #f1f3f4;
}

.question-container {
  margin: 20px 0px;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px 20px 5px 20px;
  border: #f9f9f9 solid 1px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.16);
}
.draggable-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.survey-link,
.survey-link:visited,
.add-question {
  color: #7d7d7d;
}

.survey-banner {
  position: relative;
}

.survey-banner:hover .banner-container img {
  z-index: 0;
  opacity: 0.4;
  transition: all ease 0.2s;
}

.banner-input {
  background-color: transparent;
  font-size: 56px;
  color: #7d7d7d;
  z-index: 1;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}

.banner-input input {
  display: none;
}

.banner-container {
  height: 300px;
  width: auto;
  display: flex;
  justify-content: center;
}

.banner-container img {
  max-width: 100%;
  max-height: 100%;
  z-index: 11;
}
