.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 78px;
  background-color: #ff045a;
  padding: 6px 14px;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.active {
  width: 240px;
}

.sidebar .logo_content .logo {
  color: #fff;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .logo_content .logo {
  opacity: 1;
  pointer-events: none;
}

.logo_content .logo i {
  font-size: 28px;
  margin-right: 5px;
}

.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
}

.sidebar #btn {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 6px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 50px;
  transform: translateX(-50%);
}

.sidebar.active #btn {
  left: 90%;
}

.sidebar ul {
  margin-top: 20px;
  margin-left: 0;
  padding-left: 0;
}

.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  /* line-height: 50px; */
  border-radius: 12px;
}

.sidebar ul li .nav_item {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* margin: 0 5px; */
  transition: all ease 0.5s;
  border-radius: 12px;
  white-space: nowrap;
  font-size: 24px;
}

.sidebar ul li .nav_item:hover {
  color: #ff045a;
  background-color: #fff;
}

.sidebar ul li .nav_item i {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

.sidebar ul li .nav_item span {
  opacity: 0;
}
.sidebar.active ul li .nav_item span {
  opacity: 1;
}
