.question-detail {
  display: flex;
  justify-content: space-between;
}

.question-content-input,
.question-content {
  width: 60%;
  max-width: 60%;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 5px;
  margin-bottom: 10px;
  display: block;
  word-break: break-all;
}

.question-content-input {
  height: 48px;
  border: none;
  background-color: transparent;
}

.question-type-dropdown {
  margin-bottom: 20px;
  margin-right: 40px;
  width: 120px;
  outline: none;
  border-radius: 4px;
  height: 35px;
  /* border-bottom: 1px solid rgb(75, 74, 74); */
  /* background-color: #f8f9fa; */
}

.question-answers {
  display: flex;
  flex-direction: column;
}

.question-actions {
  border-top: 1px solid #dadce0;
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.question-required-button {
  margin-left: 10px;
  margin-right: 30px;
}

.is-required-action {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.question-delete-button {
  font-size: 28px;
  color: #7d7d7d;
  margin: 10px;
  padding-right: 10px;
  border-right: 1px solid #dadce0;
}
.question-duplicate-button {
  font-size: 28px;
  color: #7d7d7d;
  margin: 5px;
}

.add-answer {
  align-self: center;
  width: fit-content;
  margin-top: 20px;
  font-size: 32px;
  color: #7d7d7d;
}

.answer-container {
  display: flex;
  align-items: center;
}

.answer-content {
  width: 60%;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.answer-input {
  width: 100%;
  border: none;
  margin-left: 10px;
  padding: 5px 0;
  background-color: transparent;
}

.delete-answer-action {
  font-size: 28px;
  height: 30px;
}
